import axios from 'axios'
import uuid from 'node-uuid'
import { getToken } from '@/libs/utils'
// import Router from '@/router'

class HttpRequest {
  constructor(baseURL = baseURL) {
    this.baseURL = baseURL ? baseURL : ''
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseURL ? this.baseURL : '',
      headers: {}
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
        // Spin.show() // 不建议开启，因为界面不友好
      }
      if (config.params === undefined) {
        config.params = {}
      }
      config.params.requestId = uuid.v1().replace(/-/g, '')
      let token = getToken()
      if (token) {
        config.headers['accessToken'] = token
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(res.statusText)
      }
    }, error => {
      this.destroy(url)
      return Promise.reject(error)
    })
  }
  request (options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest
