import axios from '@/libs/api.request'
const loginWithPwd = data => {
    return axios.request({
        url: '/_ac/login/withPwd',
        method: 'post',
        data
    })
}
const loginPhoneWithCaptcha = data => {
    return axios.request({
        url: '/_ac/login/phone/withCaptcha',
        method: 'post',
        data
    })
}
const loginPhoneSendCaptcha = data => {
    return axios.request({
        url: '/_ac/login/phone/sendCaptcha',
        method: 'post',
        data
    })
}
const loginEmailWithCaptcha = data => {
    return axios.request({
        url: '/_ac/login/email/withCaptcha',
        method: 'post',
        data
    })
}
const loginEmailSendCaptcha = data => {
    return axios.request({
        url: '/_ac/login/email/sendCaptcha',
        method: 'post',
        data
    })
}
const retrievePassPhoneSendCaptcha = data => {
    return axios.request({
        url: '/_ac/retrieve-pass/phone/sendCaptcha',
        method: 'post',
        data
    })
}
const retrievePassPhoneWithCaptcha = data => {
    return axios.request({
        url: '/_ac/retrieve-pass/phone/withCaptcha',
        method: 'post',
        data
    })
}
const retrievePassEmailSendCaptcha = data => {
    return axios.request({
        url: '/_ac/retrieve-pass/email/sendCaptcha',
        method: 'post',
        data
    })
}
const retrievePassEmailWithCaptcha = data => {
    return axios.request({
        url: '/_ac/retrieve-pass/email/withCaptcha',
        method: 'post',
        data
    })
}

const registerPhoneSendCaptcha = data => {
    return axios.request({
        url: '/_ac/register/phone/sendCaptcha',
        method: 'post',
        data
    })
}

const registerPhoneWithCaptcha = data => {
    return axios.request({
        url: '/_ac/register/phone/withCaptcha',
        method: 'post',
        data
    })
}
export const loginAPI = {
    loginWithPwd, loginPhoneWithCaptcha, loginPhoneSendCaptcha, loginEmailWithCaptcha, loginEmailSendCaptcha, retrievePassPhoneSendCaptcha, retrievePassPhoneWithCaptcha, retrievePassEmailSendCaptcha, retrievePassEmailWithCaptcha, registerPhoneSendCaptcha, registerPhoneWithCaptcha
}