
import Vue from "vue";
import areaCodes from "./area-code";

Vue.prototype.$alertableError = function (msg) {
    if (msg instanceof Object) {
        return new Error(JSON.stringify(msg));
    } else {
        return new Error(msg)
    }
}

Vue.prototype.$parseAlertTableError = function (msg) {
    if (typeof msg == 'string') {
        try {
            return JSON.parse(msg);
        } catch (e) {
            return msg;
        }
    } else {
        return msg;
    }
}
export const FormMixin = {
    props: {
        formLoading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            validateAlert: {
                hasError: false,
                msg: "",
                prop: "",
            },
            formName: 'form',
            areaCodes: areaCodes,
            sendCaptchaStatus: {
                s: false,
                time: -1
            },
            captchaBtnEnabled: false,
        }
    },
    computed: {
        disabledMobile () {
            return this.validateAlert.hasError && this.validateAlert.prop === 'accountName';
        },
    },
    methods: {
        resetValidAlert () {
            this.validateAlert.hasError = false;
            this.validateAlert.msg = "";
            this.validateAlert.prop = "";
        },
        validateOption: function (prop, status, alert) {
            if (!status) {
                alert = this.$parseAlertTableError(alert);
                if (this.$refs[this.formName]) {
                    this.$refs[this.formName].clearValidate();
                }
                if (alert.hide) {
                    this.validateAlert.hasError = false;
                } else {
                    this.validateAlert.hasError = true;
                }
                this.validateAlert.msg = alert.msg ? alert.msg : alert;
                this.validateAlert.prop = prop;
                this.validateAlert.type = alert.type ? alert.type : "error"
            } else {
                if (this.validateAlert.hasError && this.validateAlert.prop === prop) {
                    this.validateAlert.hasError = false;
                    this.validateAlert.msg = "";
                    this.validateAlert.prop = "";
                }
            }
        },
        goPrivacyPolicy () {
            window.open("https://docs.leshiguang.com/FAQ/privacy", "_blank")
        },
        goRegister () {
            this.$router.push({ name: "register" })
        },
        goRetrievePass () {
            this.$router.push({ name: "retrieve-pass" })
        },
        goLogin () {
            this.$router.push({ name: "login" })
        },
        resetSendCaptchaStatus () {
            this.sendCaptchaStatus = {
                s: false,
                time: -1
            }
        },
        setSendCaptchaStatus (current, max) {
            if (current < max) {
                current++;
                this.sendCaptchaStatus.s = true;
                this.sendCaptchaStatus.time = max - current;
                return current;
            } else {
                this.resetSendCaptchaStatus();
                return false;
            }
        },
        doRegisterSendCaptchStatus () {
            let current = 0;
            let max = 60;
            let that = this;
            let timeoutFunction = setInterval(function () {
                current = that.setSendCaptchaStatus(current, max)
                if (!current) {
                    clearInterval(timeoutFunction);
                }
            }, 1000)
        },
        sendCaptcha () {
            this.$refs[this.formName].validateField(["accountName"], (errors) => {
                if (!errors) {
                    this.$emit("sendCaptcha", this.model);
                }
            });
        },
        doSubmit () {
            this.$refs[this.formName].validate((valid) => {
                if (valid) {
                    this.$emit("submit", this.model);
                } else {
                    return false;
                }
            });
        }
    }
}